import React, { useState } from "react"

import {
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
   Row,
   Col,
} from "reactstrap"
// import "./style.scss"
import Packages from "./Packages"
import Customization from "./Customization"
import Equipments from "./Equipments"

const SalesAndMarketing = () => {
   const [activeTab, setActiveTab] = useState("1")

   const toggle = index => {
      if (activeTab !== index) setActiveTab(index)
   }

   return (
      <>
         <Nav className="custom-tabs">
            <NavItem>
               <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                     toggle("1")
                  }}
               >
                  Packages
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                     toggle("2")
                  }}
               >
                  Personalization
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                     toggle("3")
                  }}
               >
                  Equipment
               </NavLink>
            </NavItem>
         </Nav>

         <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
               <Packages />
            </TabPane>
            <TabPane tabId="2">
               <Customization />
            </TabPane>
            <TabPane tabId="3">
               <Equipments />
            </TabPane>
         </TabContent>
      </>
   )
}

export default SalesAndMarketing
