import React, {
   useState,
   useRef,
   forwardRef,
   useImperativeHandle,
   useEffect,
} from "react"
import toast from "react-hot-toast"

import "./Style.scss"
import { Modal } from "../../../../components/Modal"
import {
   finalizeMediaUtil,
   getMediaPath,
   getPreviewFile,
   initializeMediaUtil,
   uploadOnS3,
} from "../../../../utils/mediaUtils"
import PreviewImage from "../PreviewImage/PreviewImage"
import uploadImgPlaceholder from "../../../../assets/images/upload-image-placeholder.svg"
import { ImagePreview } from "components/ImagePreview"

const ImageGallery = (props, ref) => {
   let {
      id = "",
      handleSetMediaId = () => {},
      cbForProgress = () => {},
      isUploadOnServer = false,
      hasDataStructure = false,
      previousImages = [],
      removePreviousImages = () => {},
      cbRemoveImage = () => {},
      previewOnly = false,
      isMulti = true,
      isDetailView,
      MAX_IMAGES = 15,
      maxFileSize = 15 * 1024 * 1024,
   } = props

   const controller = new AbortController()
   const signal = controller.signal

   let uploadInputRef = useRef()
   const [loading, setLoading] = useState(false)
   const [selectedImages, setSelectedImages] = useState([])
   const [modalPreviewImageFile, setModalPreviewImageFile] = useState(null)
   const [modalPreviewImageUrl, setModalPreviewImageUrl] = useState(null)
   const [isModalOpen, setIsModalOpen] = useState(false)

   useEffect(() => {
      if (isUploadOnServer && selectedImages.length) {
         handleMediaUpload(...selectedImages)
      }
      return () => {
         controller.abort()
      }
   }, [selectedImages])

   const handleMediaUpload = async file => {
      setLoading(true)
      return initializeMediaUtil(file, false, signal).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials, cbForProgress)
         return await finalizeMediaUtil(credentials?.mediaId, false, signal)
            .then(res => {
               handleSetMediaId(id, res.id)
            })
            .catch(() => {})
            .finally(() => {
               setLoading(false)
            })
      })
   }

   const [selectedImageIndex, setSelectedImageIndex] = useState(0)

   const handleImageSelection = e => {
      let imageCount =
         e.target.files.length + selectedImages.length + previousImages.length

      if (imageCount > MAX_IMAGES) {
         toast.error("Cannot upload more than 15 images!")
         e.target.value = ""
         return
      }

      let newFiles = Array.from(e.target.files)

      let oversizedFiles = newFiles.find(file => file.size > maxFileSize)
      if (oversizedFiles) {
         e.target.value = ""
         return toast.error(
            `${
               MAX_IMAGES === 1 ? "Image" : "Some images"
            } exceed the maximum size of ${maxFileSize / (1024 * 1024)} Mb!`
         )
      }

      let invalidFiles = newFiles.find(
         file => !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      )
      if (invalidFiles) {
         e.target.value = ""
         return toast.error("Only PNG and JPG images are allowed!")
      }

      setSelectedImages(prev => [...prev, ...newFiles])
      e.target.value = ""
   }

   const removeImageHandler = idx => {
      handleSetMediaId(id, "")
      setSelectedImages(prev => prev.filter((_, i) => i !== idx))
   }

   useImperativeHandle(ref, () => ({ getAllImages: () => selectedImages }))

   const handleSetModalPReviewFile = img => {
      setModalPreviewImageFile(img)
   }

   const handleSetModalPreview = index => {
      setSelectedImageIndex(index)
      setIsModalOpen(true)
   }

   const handleCloseModalPreview = () => {
      setIsModalOpen(false)
      setModalPreviewImageUrl(null)
      setModalPreviewImageFile(null)
   }

   const images = previousImages.map(image => ({
      media: {
         path: image?.media?.path,
      },
   }))

   const sliderSettings = {
      customPaging: index => {
         const thumbnailUrl = images[index]?.media?.path
         return (
            <img
               src={thumbnailUrl}
               alt={`Thumbnail ${index + 1}`}
               width="30px"
               height="30px"
            />
         )
      },
      dots: true,
      dotsClass: "slick-dots",
      waitForAnimate: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      initialSlide: selectedImageIndex,
   }

   return (
      <div className="d-flex">
         <div className="d-flex flex-wrap justify-content-start">
            {!previewOnly &&
               previousImages.length < MAX_IMAGES &&
               selectedImages.length < MAX_IMAGES && (
                  <div
                     style={{ width: "fit-content", height: "fit-content" }}
                     role="button"
                     onClick={() => {
                        uploadInputRef.current.click()
                     }}
                  >
                     <div>
                        <img src={uploadImgPlaceholder} />
                     </div>

                     <input
                        multiple={isMulti}
                        accept=".jpg,.jpeg,.png,image/*"
                        type={"file"}
                        ref={uploadInputRef}
                        hidden
                        onChange={handleImageSelection}
                     />
                  </div>
               )}
            {selectedImages?.length && !previewOnly
               ? selectedImages?.map((item, index) => (
                    <PreviewImage
                       id={id}
                       loading={loading}
                       onClick={handleSetModalPReviewFile}
                       imageToPreview={getPreviewFile(item)}
                       key={index}
                       handleRemoveImage={() => removeImageHandler(index)}
                    />
                 ))
               : null}

            {previousImages?.length
               ? previousImages.map((item, idx) => (
                    <PreviewImage
                       isDetailView={isDetailView}
                       onClick={() => handleSetModalPreview(idx)}
                       hideCross={previewOnly}
                       imageToPreview={getMediaPath(item)}
                       key={idx}
                       handleRemoveImage={() => {
                          if (hasDataStructure) {
                             cbRemoveImage(
                                previousImages.filter((_, i) => i !== idx)
                             )
                          } else {
                             removePreviousImages(prev =>
                                prev.filter((_, i) => i !== idx)
                             )
                          }
                       }}
                    />
                 ))
               : null}
         </div>
         {/* To preview images */}
         {isDetailView && (
            <Modal
               isOpen={isModalOpen}
               handleModalToggling={handleCloseModalPreview}
               bodyClassName="d-flex justify-content-center"
               customButton={true}
               hideModalHeaderSeparator={true}
               headerClasses="header-container"
               sizeClasses="8"
               backdrop="static"
               modalTitle="Preview"
               scrollable={false}
            >
               {previousImages && previousImages.length > 0 && (
                  <ImagePreview
                     images={images}
                     settings={sliderSettings}
                     containerClasses="slider-container"
                     imageContainer="image-container"
                     sliderImage="slider-img"
                  />
               )}
            </Modal>
         )}
      </div>
   )
}

export default forwardRef(ImageGallery)
