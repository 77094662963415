import { Get, Put, Post, Delete } from "./api.helper"
import { store } from "store"
import Config from "Config"
import { appendQueryParams } from "utils/urlUtils"
import toast from "react-hot-toast"
import { handleFetchError } from "utils/errorHandler"
// **** AUTH APIS
export const Login = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/auth/login",
      body: bodyData,
      isAuthorized: false,
      showToaster,
   })
}

export const GetMe = () => {
   return Get({ url: "/user" })
}

export const ForgotPassword = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/auth/forget-password",
      body: bodyData,
      showToaster,
   })
}
export const ChangePassword = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/user/change-password",
      body: bodyData,
      showToaster,
   })
}

export const ForgotPasswordVerification = (
   bodyData = {},
   showToaster = true
) => {
   return Post({
      url: "/auth/forget-password/verification",
      body: bodyData,
      showToaster,
   })
}

export const ResetPassword = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/auth/reset-password",
      body: bodyData,
      showToaster,
   })
}

export const CSVFileUpload = (bodyData = {}, showToaster = true) => {
   const token = store.getState().userAuth.user.Token
   return fetch(`${Config.env().API_URL}/item/bulk-items`, {
      headers: {
         Authorization: token,
      },
      body: bodyData,
      method: "POST",
   }).then(res => handleFetchError(res))
}

export const ResendOtpCode = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/auth/resend-otp",
      body: bodyData,
      showToaster,
   })
}

export const Logout = () => {
   return Post({
      url: "/user/logout",
   })
}

export const WarehouseInventoryListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/inventory/warehouse-inventory",
      params,
      abortSignal,
   })
}

// setting apis

export const NotificationSetting = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/user/notification_setting",
      body: bodyData,
      showToaster,
   })
}

export const passwordSetting = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/user/change-password",
      body: bodyData,
      showToaster,
   })
}

//Finance Dashboard

export const GetStockListing = (params = {}) => {
   return Get({
      url: "/inventory/dashboard-shortage",
      params,
   })
}

export const GetAdminFinanceScrapReturnListing = (
   params = {},
   abortSignal = null
) => {
   return Get({ url: "/requests/warehouse-scrap-return", params, abortSignal })
}
export const AdminFinanceDashboardAnalytics = () => {
   return Get({
      url: "/inventory/dashboard",
   })
}

export const ItemCategoryCategoriesListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: "/items-category/categories",
      params,
      abortSignal,
   })
}

export const GetDepartmentInventoryListing = (params = {}) => {
   return Get({ url: "/inventory/department-inventory", params })
}

export const GetDepartmentInventoryRequestListing = (
   params = {},
   abortSignal = null
) => {
   return Get({ url: "/inventory/department-requests", params, abortSignal })
}

export const GetBuildItemsListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/configurator-build/build-items", params, abortSignal })
}

export const AddDeparmentInventoryRequest = (
   bodyData = {},
   showToaster = true
) => {
   return Post({
      url: "/inventory/department-requests",
      body: bodyData,
      showToaster,
   })
}

export const AddWarehouseInventoryRequest = (
   bodyData = {},
   showToaster = true
) => {
   return Post({
      url: "/inventory/warehouse-requests",
      body: bodyData,
      showToaster,
   })
}

export const GetDepartmentsListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/department/departments", params, abortSignal })
}

export const GetDepartmentById = (id = "") => {
   return Get({ url: `/department/${id}` })
}

export const GetItemsListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/item/items", params, abortSignal })
}

export const GetItemDetailsById = (id = "") => {
   return Get({ url: `/item/${id}` })
}

export const GetWarehouseInventoryPODetails = (id = "") => {
   return Get({ url: `/inventory/items-purchase-orders/${id}` })
}

export const GetInventoryItemById = (id = "") => {
   return Get({ url: `/inventory/items-by-id/${id}` })
}

// **** FINANCE ORDER MANAGEMENT APIS
export const OrderRequestByItemsListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/inventory/finance-requests-by-items",
      params,
      abortSignal,
   })
}

export const OrderRequestByOrderListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/inventory/warehouse-requests",
      params,
      abortSignal,
   })
}

export const PlacedOrdersOMListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/inventory/purchase-orders",
      params,
      abortSignal,
   })
}

export const WarehouseRequestDetialViewById = id => {
   return Get({
      url: `/inventory/finance-requests-by-id/${id}`,
   })
}

export const InventoryItemsById = (id, params = {}, abortSignal = null) => {
   return Get({
      url: `/inventory/items-by-id/${id}`,
      params,
      abortSignal,
   })
}

export const VendorListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/vendor/vendors",
      params,
      abortSignal,
   })
}

export const VendorDetailsById = (params = {}, abortSignal = null, id) => {
   return Get({
      url: `/vendor/${id}`,
      params,
      abortSignal,
   })
}

export const VendorItemsDetailsById = (params = {}, abortSignal = null, id) => {
   return Get({
      url: `/vendor/vendor-items/${id}`,
      params,
      abortSignal,
   })
}

export const DeleteVendor = (id, abortSignal = null) => {
   return Delete({
      url: `/vendor/${id}`,
      abortSignal,
   })
}

export const CreateOrderFinance = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/inventory/finance-order",
      body: bodyData,
      showToaster,
   })
}

export const GetVendorById = (id = "") => {
   return Get({
      url: `/vendor/${id}`,
   })
}

export const CreateVendor = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/vendor",
      body: bodyData,
      showToaster,
   })
}

export const EditVendor = (bodyData, id, abortSignal = null) => {
   return Put({
      url: `/vendor/${id}`,
      body: bodyData,
      abortSignal,
   })
}

export const UserListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/user/listing",
      params,
      abortSignal,
   })
}

export const WarehouseListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/warehouse/listing",
      params,
      abortSignal,
   })
}

export const CreateUser = (bodyData = {}) => {
   return Post({
      url: "/user/user",
      body: bodyData,
   })
}

export const DepartmentListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/department/departments",
      params,
      abortSignal,
   })
}

export const GetUserListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/user/listing",
      params,
      abortSignal,
   })
}

export const AddWarehouse = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/warehouse/warehouse",
      body: bodyData,
      showToaster,
   })
}

export const EditUser = (bodyData = {}, id = "", abortSignal = null) => {
   return Put({
      url: `/user/${id}`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateWarehouse = (id, bodyData = {}, showToaster = true) => {
   return Put({
      url: `/warehouse/${id}`,
      body: bodyData,
      showToaster,
   })
}

export const AddDepartment = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/department",
      body: bodyData,
      showToaster,
   })
}

export const UpdateDepartment = (id, bodyData = {}, showToaster = true) => {
   return Put({
      url: `/department/${id}`,
      body: bodyData,
      showToaster,
   })
}

export const DeleteDepartment = (id, abortSignal = null) => {
   return Delete({
      url: `/department/${id}`,
      abortSignal,
   })
}

export const DeleteWarehouse = (id, abortSignal = null) => {
   return Delete({
      url: `/warehouse/${id}`,
      abortSignal,
   })
}

//  Media
export const uploadMediaInit = bodyData => {
   return Post({
      url: "/media/upload/init",
      body: bodyData,
   })
}

export const GetScrapReturnListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/requests/scrap-return", params, abortSignal })
}

export const CreateScrapReturnRequest = (bodyData = {}) => {
   return Post({
      url: "/requests/scrap-return",
      body: bodyData,
   })
}

export const uploadMediaFinalize = bodyData => {
   return Post({
      url: "/media/upload/finalize",
      body: bodyData,
   })
}

export const uploadMedia = (url, blob) => {
   return fetch(url, {
      body: blob,
      method: "PUT",
   })
      .then(res => {
         return Promise.resolve(res)
      })
      .catch(err => {
         return Promise.reject(err)
      })
}

export const GetScrapReturnRequestById = (id = "", params = {}) => {
   return Get({ url: `/requests/scrap-return/${id}`, params })
}

export const GetPullbackRequestListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/requests/pullback-listing", params, abortSignal })
}

export const DepartmentFulfillmentByWarehouse = (
   bodyData = {},
   showToaster = true
) => {
   return Post({
      url: "/inventory/department-request",
      body: bodyData,
      showToaster,
   })
}

export const GetPdfReport = (params = {}, abortSignal = null) => {
   const token = store.getState().userAuth.user.Token
   const url = appendQueryParams(`${Config.env().API_URL}/pdf/pdf`, params)

   return fetch(url, {
      headers: {
         Authorization: token,
         "Content-Type": " application/pdf",
      },
      responseType: "blob",
      method: "GET",
   })
      .then(res => Promise.resolve(res))
      .catch(err => {})
}

export const DeleteInventoryItem = (id, abortSignal = null) => {
   return Delete({
      url: `/item/${id}`,
      abortSignal,
   })
}

//  Item Catalog

export const GetItemCatalogListing = (params = {}, abortSignal = null) => {
   return Get({ url: "/item-catalog/items-catalog", params, abortSignal })
}

export const GetItemCatalogDetailsById = (
   params = {},
   abortSignal = null,
   id = ""
) => {
   return Get({ url: `/item-catalog/department-catelog-items/${id}`, params })
}

export const DeleteItemCatalog = (id, abortSignal = null) => {
   return Delete({
      url: `/item-catalog/${id}`,
      abortSignal,
   })
}

export const DeleteItemFromItemCatalog = (id, abortSignal = null) => {
   return Delete({
      url: `/item-catalog/delete-item/${id}`,
      abortSignal,
   })
}

export const UpdateItemCatalog = (id, bodyData = {}, showToaster = true) => {
   return Put({
      url: `/item-catalog/${id}`,
      body: bodyData,
      showToaster,
   })
}

export const AddItemCatalog = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/item-catalog",
      body: bodyData,
      showToaster,
   })
}
export const UpdateUserPassword = (id, bodyData = {}, showToaster = true) => {
   return Post({
      url: `/user/reset-password/${id}`,
      body: bodyData,
      showToaster,
   })
}
export const GetGlobalQuantityById = (id = "", params = {}) => {
   return Get({ url: `/inventory/items-global-quantities/${id}`, params })
}

export const ItemCategoriesListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/items-category/categories",
      params,
      abortSignal,
   })
}

export const UpdateItemCategory = (id = "", bodyData = {}) => {
   return Put({
      url: `/items-category/${id}`,
      body: bodyData,
   })
}

export const AddItemCategory = (bodyData = {}) => {
   return Post({
      url: "/items-category",
      body: bodyData,
   })
}

export const AddNewItem = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/item",
      body: bodyData,
      showToaster,
   })
}

export const AddSelectedItem = (bodyData = {}, showToaster = true) => {
   return Post({
      url: "/item/synchronize-items",
      body: bodyData,
      showToaster,
   })
}

export const UpdateSelectedItem = (bodyData = {}, showToaster = true) => {
   return Put({
      url: "/item/bulk-items",
      body: bodyData,
      showToaster,
   })
}

export const UpdateItem = (id = "", bodyData = {}, showToaster = true) => {
   return Put({
      url: `/item/${id}`,
      body: bodyData,
      showToaster,
   })
}
export const GetNotifications = (params = {}, abortSignal = null) => {
   return Get({
      url: "/notification",
      params,
      abortSignal,
   })
}

export const MarkNotificationsAsRead = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/notification/read-notifications`,
      body: bodyData,
      abortSignal,
   })
}

// Timer Configuration Apis
export const AddTimerConfiguration = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/inventory-settings`,
      body: bodyData,
      abortSignal,
   })
}

export const GetTimerConfiguration = (params = {}, abortSignal = null) => {
   return Get({
      url: "/inventory-settings/inventory-setting-type",
      params,
      abortSignal,
   })
}

export const UpdateTimerConfiguration = (id = "", bodyData = {}) => {
   return Put({
      url: `/inventory-settings/${id}`,
      body: bodyData,
   })
}

export const GetPurchaseRequestsItems = (
   params = {},
   abortSignal = null,
   id = ""
) => {
   return Get({
      url: `/inventory/warehouse-requests-items/${id}`,
      params,
      abortSignal,
   })
}

export const GetWarehouseItemsReceiving = (
   params = {},
   abortSignal = null,
   id = ""
) => {
   return Get({
      url: `/inventory/warehouse-requests-items-receivings/${id}`,
      params,
      abortSignal,
   })
}
export const GetPurchaseOrdersItems = (
   params = {},
   abortSignal = null,
   id = ""
) => {
   return Get({
      url: `/inventory/purchase-orders-items/${id}`,
      params,
      abortSignal,
   })
}

export const CancelPOItem = (id = "", bodyData = {}) => {
   return Put({
      url: `/inventory/cancel-purchase-order-item/${id}`,
      body: bodyData,
   })
}

// Configurator Settings

// ==>  Trailers

export const GetTrailersListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/configurator-trailer/trailers",
      params,
      abortSignal,
   })
}

export const GetTrailersDetails = (id = "") => {
   return Get({
      url: `/configurator-trailer/${id}`,
   })
}

export const UpdateTrailer = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-trailer/${id}`,
      body: bodyData,
   })
}

export const CreateTrailer = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-trailer`,
      body: bodyData,
      abortSignal,
   })
}

export const DeleteTrailer = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-trailer/${id}`,
      abortSignal,
   })
}

// ==>  Build
export const GetBuildStationsListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/configurator-build/listing",
      params,
      abortSignal,
   })
}

export const GetBuildStationDetails = (id = "", bodyData = {}) => {
   return Get({
      url: `url/${id}`,
      body: bodyData,
   })
}

export const DeleteBuildStation = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-build/${id}`,
      abortSignal,
   })
}

export const CreateBuildStation = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-build`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateBuildStation = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-build/${id}`,
      body: bodyData,
   })
}

export const GetBuildStationItemsListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/url`,
      params,
      abortSignal,
   })
}

export const GetBuildStationItemDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/url${id}`,
      params,
      abortSignal,
   })
}

// ==>  Base Features
export const GetBaseFeaturesCategoriesListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: "/configurator-base-features/listing",
      params,
      abortSignal,
   })
}

export const GetBaseFeatureCategoryDetails = (id = "", bodyData = {}) => {
   return Get({
      url: `/configurator-base-features/${id}`,
      body: bodyData,
   })
}

export const DeleteBaseFeatureCategory = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-base-features/category/${id}`,
      abortSignal,
   })
}

export const DeleteBaseFeatureCategoryItem = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-base-features/subtype/${id}`,
      abortSignal,
   })
}

export const CreateBaseFeatureCategory = (
   bodyData = {},
   abortSignal = null
) => {
   return Post({
      url: `/configurator-base-features/category`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateBaseFeatureCategory = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-base-features/category/${id}`,
      body: bodyData,
   })
}

export const UpdateBaseFeatureCategorySequence = (bodyData = {}) => {
   return Put({
      url: `/configurator-base-features/basefeature-sequence`,
      body: bodyData,
   })
}

export const GetBaseFeatureCategoryItemsListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-base-features/subTypes`,
      params,
      abortSignal,
   })
}

export const GetBaseFeatureCategoryItemDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-base-features/subType/${id}`,
      params,
      abortSignal,
   })
}

export const AddBaseFeatureCategoryItems = (
   bodyData = {},
   abortSignal = null
) => {
   return Post({
      url: `/configurator-base-features/subtype`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateBaseFeatureCategoryItems = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-base-features/subtype/${id}`,
      body: bodyData,
   })
}

// ==>  Packages

export const GetPackagesListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/configurator-package/packages",
      params,
      abortSignal,
   })
}
export const GetPackageDetails = (id = "", params = {}, abortSignal = null) => {
   return Get({
      url: `/configurator-package/${id}`,
      params,
      abortSignal,
   })
}

export const CreatePackage = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-package`,
      body: bodyData,
      abortSignal,
   })
}
export const UpdatePackage = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-package/${id}`,
      body: bodyData,
   })
}
export const DeletePackage = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-package/${id}`,
      abortSignal,
   })
}

export const GetPackageItemsListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/configurator-package/package-items",
      params,
      abortSignal,
   })
}
export const GetPackageItemDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-package/package-item/${id}`,
      params,
      abortSignal,
   })
}

export const CreatePackageItem = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-package/package-item`,
      body: bodyData,
      abortSignal,
   })
}
export const UpdatePackageItem = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-package/package-item/${id}`,
      body: bodyData,
   })
}

export const GetConfiguratorBuildItems = (params = {}, abortSignal = null) => {
   return Get({
      url: "/configurator-build/trailer/build-items",
      params,
      abortSignal,
   })
}

// ==>  Equipment

export const GetEquipmentsCategoryItemsListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-equipment/subTypes`,
      params,
      abortSignal,
   })
}

export const GetEquipmentsCategoryListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: "/configurator-equipment/equipments",
      params,
      abortSignal,
   })
}

export const GetEquipmentCategoryDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-equipment/${id}`,
      params,
      abortSignal,
   })
}

export const DeleteEquipment = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-equipment/category/${id}`,
      abortSignal,
   })
}
export const DeleteEquipmentCategoryItem = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-equipment/subtype/${id}`,
      abortSignal,
   })
}
export const UpdateEquipment = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-equipment/${id}`,
      body: bodyData,
   })
}

export const CreateEquipmentCategory = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-equipment/category`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateEquipmentCategory = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-equipment/category/${id}`,
      body: bodyData,
   })
}

export const AddEquipmentCategoryItems = (
   bodyData = {},
   abortSignal = null
) => {
   return Post({
      url: `/configurator-equipment/subType`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateEquipmentCategoryItems = (
   id = "",
   bodyData = {},
   abortSignal = null
) => {
   return Put({
      url: `/configurator-equipment/subType/${id}`,
      body: bodyData,
      abortSignal,
   })
}

export const GetEquipmentCategoryItemDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-equipment/subType/${id}`,
      params,
      abortSignal,
   })
}

// ==>  Summary
export const GetSummariesListing = (params = {}, abortSignal = null) => {
   return Get({
      url: "/summary/summaries",
      params,
      abortSignal,
   })
}

export const GetSummaryDetails = (id = "", params = {}, abortSignal = null) => {
   return Get({
      url: `/summary/${id}`,
      params,
      abortSignal,
   })
}

export const DeleteSummary = (id, abortSignal = null) => {
   return Delete({
      url: `/summary/${id}`,
      abortSignal,
   })
}
export const UpdateSummary = (id = "", bodyData = {}) => {
   return Put({
      url: `/summary/${id}`,
      body: bodyData,
   })
}

export const CreateSummary = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/summary`,
      body: bodyData,
      abortSignal,
   })
}

// Customization

export const GetCustomizationCategoryItemsListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-customization/subTypes`,
      params,
      abortSignal,
   })
}

export const GetCustomizationCategoryListing = (
   params = {},
   abortSignal = null
) => {
   return Get({
      url: "/configurator-customization/customizations",
      params,
      abortSignal,
   })
}

export const GetCustomizationCategoryDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-customization/${id}`,
      params,
      abortSignal,
   })
}

export const DeleteCustomizationCategory = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-customization/category/${id}`,
      abortSignal,
   })
}

export const DeleteCustomizationCategoryItem = (id, abortSignal = null) => {
   return Delete({
      url: `/configurator-customization/subtype/${id}`,
      abortSignal,
   })
}

export const UpdateCustomizationCategory = (id = "", bodyData = {}) => {
   return Put({
      url: `/configurator-customization/category/${id}`,
      body: bodyData,
   })
}

export const CreateCustomizationCategory = (
   bodyData = {},
   abortSignal = null
) => {
   return Post({
      url: `/configurator-customization/category`,
      body: bodyData,
      abortSignal,
   })
}

export const AddCustomizationCategoryItems = (
   bodyData = {},
   abortSignal = null
) => {
   return Post({
      url: `/configurator-customization/subType`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateCustomizationCategoryItems = (
   id = "",
   bodyData = {},
   abortSignal = null
) => {
   return Put({
      url: `/configurator-customization/subType/${id}`,
      body: bodyData,
      abortSignal,
   })
}

export const GetCustomizationCategoryItemDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/configurator-customization/subType/${id}`,
      params,
      abortSignal,
   })
}

// Clone Configurator Trailer
export const CloneConfiguratorTrailer = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/configurator-trailer/clone-trailer`,
      body: bodyData,
      abortSignal,
   })
}

//  Trailer Orders

export const GetOrderedTrailersListing = (params = {}, abortSignal = null) => {
   return Get({
      url: `/configurator-order/orders`,
      params,
      abortSignal,
   })
}

// Trailer Management
export const GeTrailersTypesListing = (params = {}, abortSignal = null) => {
   return Get({
      url: `/trailer-managment`,
      params,
      abortSignal,
   })
}

export const AddTrailerType = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/trailer-managment`,
      body: bodyData,
      abortSignal,
   })
}

export const UpdateTrailerType = (
   id = "",
   bodyData = {},
   abortSignal = null
) => {
   return Put({
      url: `/trailer-managment/${id}`,
      body: bodyData,
      abortSignal,
   })
}

export const DeleteTrailerType = (id, abortSignal = null) => {
   return Delete({
      url: `/trailer-managment/${id}`,
      abortSignal,
   })
}

export const GetTrailerTypeDetails = (
   id = "",
   params = {},
   abortSignal = null
) => {
   return Get({
      url: `/trailer-managment/${id}`,
      params,
      abortSignal,
   })
}

export const DirectPOTransfer = (bodyData = {}, abortSignal = null) => {
   return Post({
      url: `/inventory/generate-warehouse-order`,
      body: bodyData,
      abortSignal,
   })
}

// Countsheets

export const CountsheetItemDetails = (params = {}, abortSignal = null) => {
   return Get({
      url: `/department/item-audit-history`,
      params,
      abortSignal,
   })
}

export const CountsheetListing = (params = {}, abortSignal = null) => {
   return Get({
      url: `/department/audit-items`,
      params,
      abortSignal,
   })
}
