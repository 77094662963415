import { Row, Col } from "reactstrap"
import React from "react"

import PricingDetail from "./PricingDetail"
import PricingSection from "./PricingSection"
import { TrailerSize } from "constants"

const TrailerOrderDetails = ({ data }) => {
   let trailerPrice = Number(data.note.trailerData.price)

   let totalCustomizePackagesPrice = Object.values(
      data.note.allSelectedCustomizePackages
   ).reduce((sum, pkg) => {
      return sum + parseFloat(pkg.price)
   }, 0)

   let totalCustomizePackagesVariationPrice = Object.values(
      data.note.allSelectedCustomizePackages
   ).reduce((sum, pkg) => {
      const variationsPrice =
         pkg.selectedVariations?.reduce((subSum, variation) => {
            if (variation.checked) {
               return subSum + parseFloat(variation.price)
            }
            return subSum
         }, 0) || 0

      return sum + variationsPrice
   }, 0)

   let totalEquipmentPackagesPrice = Object.values(
      data.note.allSelectedEquipmentPackages
   ).reduce((sum, pkg) => {
      return sum + parseFloat(pkg.price)
   }, 0)

   let totalEquipmentPackagesVariationPrice = Object.values(
      data.note.allSelectedEquipmentPackages
   ).reduce((sum, pkg) => {
      const variationsPrice =
         pkg.selectedVariations?.reduce((subSum, variation) => {
            if (variation.checked) {
               return subSum + parseFloat(variation.price)
            }
            return subSum
         }, 0) || 0

      return sum + variationsPrice
   }, 0)

   let totalPackagePrice = Object.values(data.note.selectedPackages).reduce(
      (sum, pkg) => {
         return sum + parseFloat(pkg.price)
      },
      0
   )
   let totalPackageItemsPrice = Object.values(
      data.note.selectedPackages
   ).reduce((sum, pkg) => {
      const variationsPrice =
         pkg.selectedItems?.reduce((subSum, variation) => {
            return subSum + parseFloat(variation.totalPrice)
         }, 0) || 0

      return sum + variationsPrice
   }, 0)
   totalPackagePrice = totalPackagePrice + totalPackageItemsPrice
   let prices = [
      trailerPrice,
      totalPackagePrice,
      totalCustomizePackagesPrice,
      totalCustomizePackagesVariationPrice,
      totalEquipmentPackagesPrice,
      totalEquipmentPackagesVariationPrice,
   ]

   const totalSum = prices.reduce(
      (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
      0
   )

   const selectedPackages = Object.values(data.note.selectedPackages)

   const selectedCustomizePackages = Object.values(
      data.note.allSelectedCustomizePackages
   )

   const selectedEquipmentPackages = Object.values(
      data.note.allSelectedEquipmentPackages
   )

   return (
      <div className="p-4">
         <Row>
            <Col>
               <Row className="d-flex mb-2 justify-content-between">
                  <Col className="fw-bold">
                     {`${TrailerSize.getDisplayTextKey(
                        data.note?.trailerData.configuratorTrailerSizeType[0]
                     )}-${data.note?.trailerData.model}`}
                  </Col>
                  <Col className="text-right ">
                     ${Number(trailerPrice).toFixed(2)}
                  </Col>
               </Row>
               <Row className="d-flex justify-content-between mb-4 mt-4">
                  <Col className="text-left fw-bold">Personalization</Col>
                  <Col className="text-right">
                     $
                     {(
                        Number(totalCustomizePackagesPrice) +
                        Number(totalCustomizePackagesVariationPrice)
                     ).toFixed(2)}
                  </Col>
               </Row>
               {selectedCustomizePackages.map((config, index) => (
                  <PricingSection
                     key={index}
                     title={config.name}
                     price={Number(config.price)?.toFixed(2)}
                     details={config.subTypePropertyVariations}
                  />
               ))}

               <Row className="mb-4 mt-4">
                  <Col className="d-flex justify-content-between my-1">
                     <div className="text-left fw-bold">Packages</div>
                     <div>${Number(totalPackagePrice).toFixed(2)}</div>
                  </Col>
               </Row>
               {selectedPackages.map((detail, index) => (
                  <PricingDetail
                     key={index}
                     label={detail.packageName}
                     price={Number(
                        detail.selectedItems.reduce((acc, item) => {
                           return Number(acc) + Number(item.totalPrice)
                        }, Number(detail?.price))
                     )?.toFixed(2)}
                  />
               ))}

               <Row className="d-flex justify-content-between my-4">
                  <Col className="fw-bold">Equipment</Col>
                  <Col className="text-right">
                     $
                     {(
                        Number(totalEquipmentPackagesPrice) +
                        Number(totalEquipmentPackagesVariationPrice)
                     ).toFixed(2)}
                  </Col>
               </Row>
               {selectedEquipmentPackages.map((config, index) => {
                  return (
                     <PricingSection
                        key={index}
                        title={config.name}
                        price={Number(config.price)?.toFixed(2)}
                        details={config.subTypePropertyVariations}
                     />
                  )
               })}
               <Row className="my-6"></Row>
               <Row className="d-flex justify-content-between my-4">
                  <Col className="fw-bold">Total Price</Col>
                  <Col className="text-right">
                     <h3>${Number(totalSum).toFixed(2)}</h3>{" "}
                  </Col>
               </Row>
            </Col>
         </Row>
      </div>
   )
}

export default TrailerOrderDetails
