import React, { useState } from "react"
import { Input, Label } from "reactstrap"

const TextInput = props => {
   const {
      name = "",
      type = "text",
      placeholder = "Enter Text",
      className = "",
      onFocus = () => {},
      onChange = () => {},
      onBlur = () => {},
      value,
      label = "",
      labelClass = "",
      inputClass = "",
      invalid,
      valid,
      withIcon = false,
      tickIcon = false,
      rightText = "",

      ...rest
   } = props

   const [hidePass, setHidePass] = useState(true)
   return (
      <div
         className={`gt-text-input-wrap gt-text-input-type-${type} ${className}`}
      >
         {label && (
            <Label
               className={`gt-text-input-label clr-gray-dark fs-13 ${labelClass}`}
               for={name}
            >
               {label}
            </Label>
         )}
         <span className={`w-100 ${withIcon && "position-relative"}`}>
            <Input
               autoComplete="off"
               onFocus={onFocus}
               name={name}
               type={
                  type == "password" ? (hidePass ? "password" : "text") : type
               }
               invalid={invalid}
               valid={valid}
               placeholder={placeholder}
               className={`gt-text-input-field shadow-none ${inputClass} ${
                  withIcon && type != "date" && "gt-with-icon-text"
               } ${withIcon && type == "date" && "gt-date-icon"}`}
               onChange={onChange}
               onBlur={onBlur}
               value={value}
               {...rest}
            />
            {withIcon && type != "date" ? (
               <div
                  className={`position-absolute d-flex bottom-0 end-0 align-items-center gt-text-icon-wrapper ${
                     type == "date" ? "h-50" : "h-100"
                  }`}
               >
                  {rightText && (
                     <span className="gtw-41 fs-13 clr-dark-gray-3">
                        {rightText}
                     </span>
                  )}
                  <span
                     className={`h-100 d-flex gtw-41 ${
                        tickIcon && "bg-settings-tick-icon d-flex gtw-41"
                     }`}
                     onClick={() => {
                        if (type == "password") {
                           setHidePass(prevState => !prevState)
                        }
                     }}
                  >
                     {type == "password" && (
                        <i
                           className={`mdi ${
                              hidePass ? "mdi-eye" : "mdi-eye-off"
                           } clr-dark-gray-3 fs-18 m-auto`}
                        ></i>
                     )}
                     {tickIcon && (
                        <i className="bx bx-check fs-30 fw-600 text-white m-auto"></i>
                     )}
                  </span>
               </div>
            ) : null}
         </span>
      </div>
   )
}

export default TextInput
