import React, { useState } from "react"
import toast from "react-hot-toast"
import { Form } from "reactstrap"

import DeleteIcon from "../../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { Modal } from "components/Modal"
import { TextInput } from "components/TextInput"
import { FIELDS_NAME } from "./FormConfig"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"

const COLUMNS = {
   SKU: "SKU",
   ITEM_NAME: "Item Name",
   ACTIONS: "Actions",
   QUANTITY: "Quantity",
}

const VariationItemsModal = ({
   formik,
   isDetailView = false,
   itemsOptions,
   setItemsOptions,
   handleSelectAItem,
   handleSearchItems,
   uniqueKey,
   variationItems,
   handleRemoveVariationItems,
   handleSetItemQuantity,
}) => {
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleModalToggle = () => {
      setIsModalOpen(!isModalOpen)
   }

   const handleSave = e => {
      e.preventDefault()

      const items = variationItems[uniqueKey]?.variationItems || []
      const hasEmptyFields = items.some(
         item => !item.quantity || item.quantity <= 0
      )

      if (hasEmptyFields) {
         toast.error("All items must have a valid quantity greater than 0.")
         return
      }

      if (!items.length) {
         toast.error("Cannot save without items.")
         return
      }

      handleModalToggle()
   }

   return (
      <div>
         <div className="d-flex  gap-2 align-items-center">
            <button
               disabled={!formik.values[FIELDS_NAME.NAME]}
               onClick={handleModalToggle}
               className="btn btn-primary"
               type="button"
            >
               {isDetailView ? "View Inventory" : "Manage Inventory"}
            </button>
            {variationItems[uniqueKey]?.variationItems?.length ? (
               <small>{`${variationItems[uniqueKey]?.variationItems?.length} item(s) selected`}</small>
            ) : null}
         </div>

         <Modal
            isOpen={isModalOpen}
            handleModalToggling={handleModalToggle}
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            size="xl"
            backdrop="static"
         >
            <Form onSubmit={handleSave} className="w-full mx-4 z-3">
               <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                     <small className="fw-bold p-0 m-0">Inventory Items</small>

                     {!isDetailView && (
                        <div>
                           <div
                              className="m-3"
                              style={{
                                 width: "20.813rem",
                              }}
                           >
                              <AutoComplete
                                 handleBlur={() =>
                                    setItemsOptions(prev => {
                                       return {
                                          ...prev,
                                          page: 1,
                                          Q: "",
                                       }
                                    })
                                 }
                                 onChange={option =>
                                    handleSelectAItem(option, uniqueKey)
                                 }
                                 onInputChange={handleSearchItems}
                                 placeholder="Select item"
                                 classNamePrefix="add-new-req"
                                 customStyles={AutoCompleteStyling}
                                 options={itemsOptions?.data}
                                 value={null}
                                 isLoading={itemsOptions?.fetching}
                                 isDisabled={isDetailView}
                              />
                           </div>
                        </div>
                     )}
                  </div>
               </div>

               <div>
                  <DataTable
                     className="height-style"
                     data={variationItems[uniqueKey].variationItems}
                     config={[
                        {
                           title: COLUMNS.SKU,
                           render: data => data.skuNo,
                        },
                        {
                           title: COLUMNS.ITEM_NAME,
                           render: data => data.itemName,
                        },
                        {
                           title: COLUMNS.QUANTITY,
                           render: data => (
                              <>
                                 {!isDetailView ? (
                                    <TextInput
                                       type="number"
                                       step="0.01"
                                       max="99999999.99"
                                       placeholder="Qty"
                                       value={data.quantity}
                                       onChange={e =>
                                          handleSetItemQuantity(
                                             uniqueKey,
                                             data.id,
                                             e.target.value
                                          )
                                       }
                                    />
                                 ) : (
                                    <span>{data.quantity}</span>
                                 )}
                              </>
                           ),
                        },
                        {
                           title: COLUMNS.ACTIONS,
                           render: data => (
                              <>
                                 {!isDetailView ? (
                                    <>
                                       <img
                                          src={DeleteIcon}
                                          className="pointer"
                                          onClick={() =>
                                             handleRemoveVariationItems(
                                                uniqueKey,
                                                data.id
                                             )
                                          }
                                       />
                                    </>
                                 ) : (
                                    "-"
                                 )}
                              </>
                           ),
                        },
                     ]}
                  />

                  <div className="d-flex justify-content-center mt-3 mt-2">
                     <Button
                        type={"button"}
                        title={isDetailView ? "Done" : "Save"}
                        className="header-button"
                        onClick={handleSave}
                     />
                  </div>
               </div>
            </Form>
         </Modal>
      </div>
   )
}

export default VariationItemsModal
