import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import toast from "react-hot-toast"

import eyeIcon from "../../../assets/images/icons/eye-icon.svg"
import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"
import DeleteIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"

import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import {
   DeleteCustomizationCategoryItem,
   GetCustomizationCategoryItemsListing,
   UpdateCustomizationCategoryItems,
} from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import { EquipmentCategoryItemsStatus } from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import ManageCustomizationCategoryItems from "./ManageCustomizationCategoryItems"
import DeleteModal from "../CommonUI/Modal/DeleteModal"

const COLUMNS = {
   ITEM_NAME: "Item",
   PRICE: "Price",
   DATE_EDITED: "Date edited",
   STATUS: "Status",
   ACTIONS: "Actions",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const CustomizationCategoryItems = ({}) => {
   const { trailerId, categoryId } = useParams()
   const history = useHistory()
   const [statusFilters, setStatusFilters] = useState(null)
   const [selection, setSelection] = useState(null)
   const [openDelModal, setOpenDelModal] = useState(false)

   const {
      data,
      isFetching,
      page,
      searchText,
      pageSize,
      total,
      handleSearchChange,
      request,
      handlePageClick,
      onChangePageSize,
      setTableDataChangeLoading,
      tableDataChangeLoading,
      setData,
   } = useLocalPaginatedRequest({
      requestFn: GetCustomizationCategoryItemsListing,
      params: {
         ...(statusFilters?.value && { status: statusFilters?.value }),
         categoryId: categoryId,
      },
      deps: [statusFilters],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const [eventsType, setEventsType] = useState({
      isManage: null,
      isUpdate: null,
      isDetails: null,
   })

   const handleInitManage = (obj = null) => {
      setEventsType(prev => ({ ...prev, isManage: true }))
      setSelection(obj)
   }

   const handleInitViewDetails = obj => {
      setSelection(obj)
      setEventsType(prev => ({ ...prev, isDetails: true, isManage: true }))
   }

   const handleSelectStatusOption = option => {
      setStatusFilters(option)
   }

   const handleStatusChange = async (currStatus, id) => {
      let status =
         currStatus === EquipmentCategoryItemsStatus.Active
            ? EquipmentCategoryItemsStatus.Inactive
            : EquipmentCategoryItemsStatus.Active
      let dataClone = [...data]
      dataClone.map(el => {
         if (el?.id == id) el.status = status
      })
      setData(dataClone)
      let payload = {
         status: status,
      }
      try {
         setTableDataChangeLoading(true)
         let res = await UpdateCustomizationCategoryItems(id, payload)
         toast.success("Item status has been updated !")
      } catch (err) {
         toast.error(err.message)
         let dataClone = [...data]
         dataClone.map(el => {
            if (el?.id == id) el.status = currStatus
         })
         setData(dataClone)
      } finally {
         setTableDataChangeLoading(false)
      }
   }

   const handleInitDelete = obj => {
      setSelection(obj)
      setOpenDelModal(prev => !prev)
   }

   return (
      <>
         <div className="mb-3 trailer-container">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
               <div />

               <Button
                  className={`header-button headerButtonResponsive`}
                  title="Add Item"
                  onClick={() => handleInitManage({})}
               />
            </div>
         </div>

         <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
               <div className="status-search-con wh-im-dpgi-status  me-2 ">
                  <AutoComplete
                     value={statusFilters}
                     isClearable
                     onChange={val => handleSelectStatusOption(val)}
                     isSearchable={false}
                     placeholder="Select status"
                     customStyles={customStyles.AdminAppSelectStyles}
                     classNamePrefix="status-header-search-admin"
                     options={convertToSelectOptions(
                        EquipmentCategoryItemsStatus
                     )}
                  />
               </div>
               <div className="ad-location-search-box">
                  <SearchBox
                     // inputClasses="custom-input-search custom-input-search-height"
                     inputClasses="vendor-search-input input-search"
                     labelClass="d-none"
                     placeholder="Search"
                     searchIcon={true}
                     // searchIconClass="custom-input-search-icon"
                     // conClass={`search-input-container-class header-search-responsiveness`}
                     searchIconClass="custom-input-search-icon"
                     conClass={`search-input-container-class`}
                     searchText={searchText}
                     onChange={handleSearchChange}
                  />
               </div>
            </div>
         </div>
         <Row>
            <Col md={12}>
               <DataTable
                  data={data}
                  tableClasses="customization-category-table"
                  loading={isFetching}
                  // className="vendor-data-container mt-3"
                  config={[
                     {
                        title: COLUMNS.ITEM_NAME,
                        className: "",
                        render: data => data?.name || "-",
                     },
                     {
                        title: COLUMNS.PRICE,
                        className: "",
                        render: data => "$" + data?.price || "-",
                     },
                     {
                        title: COLUMNS.DATE_EDITED,
                        className: "",
                        render: data => {
                           return convertDateTime({
                              date: data.updatedAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           })
                        },
                     },

                     {
                        title: COLUMNS.STATUS,
                        className: "",
                        render: data => (
                           <ActiveInactiveSwitch
                              isDisable={tableDataChangeLoading}
                              id={data.id}
                              name={`${data.id}`}
                              onChange={e =>
                                 handleStatusChange(data?.status, data?.id)
                              }
                              isChecked={
                                 data?.status ==
                                 EquipmentCategoryItemsStatus.Active
                              }
                           />
                        ),
                     },

                     {
                        title: COLUMNS.ACTIONS,

                        render: data => (
                           <div className="d-flex gap-2 pointer">
                              <img
                                 src={eyeIcon}
                                 onClick={() => handleInitViewDetails(data)}
                              />

                              <img
                                 src={editIcon}
                                 onClick={() => handleInitManage(data)}
                              />
                              <img
                                 src={DeleteIcon}
                                 onClick={() => handleInitDelete(data)}
                              />
                           </div>
                        ),
                     },
                  ]}
               />
            </Col>
            <Pagination
               currentPage={page}
               totalData={total}
               onPageClick={handlePageClick}
               // className="pagination-padding"
            />
         </Row>

         {Object.values(eventsType).includes(true) && (
            <ManageCustomizationCategoryItems
               isUpdating={Boolean(eventsType.isManage && selection)}
               isDetailView={eventsType.isDetails}
               isOpen={eventsType.isManage}
               id={selection?.id}
               onSuccess={request}
               onToggleModal={() => {
                  setSelection({})
                  setEventsType(prev => ({
                     ...prev,
                     isManage: false,
                     isDetails: false,
                  }))
               }}
            />
         )}

         <DeleteModal
            isOpen={openDelModal}
            toggleFunction={setOpenDelModal}
            id={selection?.id}
            apiFunction={DeleteCustomizationCategoryItem}
            title={"Personalization Item"}
            requestFunction={request}
         />
      </>
   )
}

export default CustomizationCategoryItems
