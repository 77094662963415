import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import toast from "react-hot-toast"

import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"
import DeleteIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"
import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import {
   GetCustomizationCategoryDetails,
   GetCustomizationCategoryListing,
   UpdateCustomizationCategory,
   GetBaseFeaturesCategoriesListing,
   CreateCustomizationCategory,
   DeleteCustomizationCategory,
} from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import { EquipmentCategoriesStatus, TrailerStatus } from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import ManageSettingsCategory from "../CommonUI/ManageSettingsCategory/ManageSettingsCategory"
import useAsyncSelect from "hooks/useAsyncSelect"
import DeleteModal from "../CommonUI/Modal/DeleteModal"

const COLUMNS = {
   CATEGORY: "Category",
   BASE_FEATURE: "Base Feature",
   DATE_EDITED: "Date edited",
   NO_OF_ITEMS: "No. of items",
   STATUS: "Status",
   ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TITLES = {
   MODAL_ADD_TITLE: "Add category",
   MODAL_UPDATE_TITLE: "Update category",
   MODAL_DETAILS_TITLE: "Category details",
   ADD_BTN_TEXT: "Save",
   UPDATE_BTN_TEXT: "Update",
   DETAILS_BTN_TEXT: "Done",
}

const Customization = ({}) => {
   const history = useHistory()
   const { trailerId } = useParams()
   const [statusFilters, setStatusFilters] = useState(null)
   const [baseFeatureFilter, setBaseFeatureFilter] = useState(null)
   const [selection, setSelection] = useState({})
   const [openDelModal, setOpenDelModal] = useState(false)

   const {
      setSelectedOption: setSelectedBaseFeature,
      selectEntity: baseFeaturesOptions,
      setSelectEntity: setBaseFeaturesOptions,
      handleSearchOnOptions: handleSearchBaseFeature,
      selectedOption: selectedBaseFeature,
      handleSelectAOption: handleSelectABaseFeatureOption,
   } = useAsyncSelect({
      apiFunc: GetBaseFeaturesCategoriesListing,
      labelKey: "name",
      valueKey: "id",
      restrictExecutionOnRender: false,
      params: {
         trailerId: trailerId,
      },
      onOptionSelect: option => {
         option?.id &&
            setBaseFeatureFilter({
               configuratorBaseFeaturesId: option.id,
            })
      },
   })

   const {
      data,
      isFetching,
      page,
      searchText,
      pageSize,
      total,
      handleSearchChange,
      request,
      handlePageClick,
      onChangePageSize,
      setTableDataChangeLoading,
      tableDataChangeLoading,
      setData,
   } = useLocalPaginatedRequest({
      requestFn: GetCustomizationCategoryListing,
      params: {
         trailerId: trailerId,

         ...(statusFilters?.value && { status: statusFilters?.value }),

         ...(baseFeatureFilter?.configuratorBaseFeaturesId &&
            selectedBaseFeature?.value && {
               configuratorBaseFeaturesId:
                  baseFeatureFilter?.configuratorBaseFeaturesId,
            }),
         direction: "ASC",
         column: "sequenceNo",
      },
      deps: [statusFilters, baseFeatureFilter, selectedBaseFeature],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const [eventsType, setEventsType] = useState({
      isManage: null,
      isUpdate: null,
      isDetails: null,
   })

   const handleInitManage = obj => {
      setEventsType(prev => ({ ...prev, isManage: true }))
      setSelection(obj)
   }

   const handleNavigateToCategoryItems = obj => {
      setSelection(obj)

      return history.push(
         getRoute(
            APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION_ITEMS,
            {
               trailerId: trailerId,
               categoryId: obj.id,
            }
         )
      )
   }
   const handleSelectStatusOption = option => {
      setStatusFilters(option)
   }

   const handleStatusChange = async (currStatus, id) => {
      let status =
         currStatus === EquipmentCategoriesStatus.Active
            ? EquipmentCategoriesStatus.Inactive
            : EquipmentCategoriesStatus.Active
      let dataClone = [...data]
      dataClone.map(el => {
         if (el?.id == id) el.status = status
      })
      setData(dataClone)
      let payload = {
         status: status,
      }
      try {
         setTableDataChangeLoading(true)
         let res = await UpdateCustomizationCategory(id, payload)
         toast.success("Category status has been updated !")
      } catch (err) {
         toast.error(err.message)
         let dataClone = [...data]
         dataClone.map(el => {
            if (el?.id == id) el.status = currStatus
         })
         setData(dataClone)
      } finally {
         setTableDataChangeLoading(false)
      }
   }

   const handleInitDelete = obj => {
      setSelection(obj)
      setOpenDelModal(prev => !prev)
   }

   return (
      <>
         {/* 
         [Commented as no longer needed right now]
         
         <div className="mb-3 trailer-container">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
               <div />

               <Button
                  className={`header-button headerButtonResponsive`}
                  title="Add Category"
                  onClick={() => handleInitManage({})}
               />
            </div>
         </div> */}

         <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between admin-inventory-request-form-fields ">
               <div className="d-flex justify-content-start align-items-center admin-inventory-request-autocomplete">
                  <div className="min-250-w margin-right-handle me-2">
                     <AutoComplete
                        value={statusFilters}
                        isClearable
                        onChange={val => handleSelectStatusOption(val)}
                        isSearchable={false}
                        placeholder="Select status"
                        customStyles={customStyles.AdminAppSelectStyles}
                        classNamePrefix="status-header-search-admin"
                        options={convertToSelectOptions(
                           EquipmentCategoriesStatus
                        )}
                     />
                  </div>
                  <div className="min-250-w margin-top-handle">
                     {/* <AutoComplete
                        handleBlur={() =>
                           setBaseFeaturesOptions(prev => {
                              return {
                                 ...prev,
                                 page: 1,
                                 Q: "",
                              }
                           })
                        }
                        onChange={handleSelectABaseFeatureOption}
                        onInputChange={handleSearchBaseFeature}
                        placeholder="Search base features"
                        classNamePrefix="status-header-search-admin"
                        customStyles={customStyles.AdminAppSelectStyles}
                        options={baseFeaturesOptions?.data}
                        isClearable={true}
                        value={selectedBaseFeature}
                        isLoading={baseFeaturesOptions?.fetching}
                     /> */}
                  </div>
               </div>
               <div className="ad-search-container location-search-box  ">
                  <SearchBox
                     inputClasses="vendor-search-input input-search "
                     labelClass="d-none"
                     placeholder="Search"
                     searchIcon={true}
                     searchIconClass="vendor-table-search-icon"
                     conClass="vendor-table-search-inner-class admin-inventory-request-search-box-responsive"
                     searchText={searchText}
                     onChange={handleSearchChange}
                  />
               </div>
            </div>
         </div>

         <Row>
            <Col md={12}>
               <DataTable
                  data={data}
                  tableClasses="customization-table"
                  loading={isFetching}
                  // className="vendor-data-container mt-3"
                  config={[
                     {
                        title: COLUMNS.CATEGORY,
                        className: "",
                        render: data => data.name || "-",
                     },
                     {
                        title: COLUMNS.BASE_FEATURE,
                        className: "",
                        render: data =>
                           data.configuratorBaseFeatures?.name || "-",
                     },
                     {
                        title: COLUMNS.DATE_EDITED,
                        className: "",
                        render: data => {
                           return convertDateTime({
                              date: data.updatedAt,
                              customFormat:
                                 dateTimeFormat.casitaCustomDateFormat,
                              dateOnly: true,
                           })
                        },
                     },

                     {
                        title: COLUMNS.NO_OF_ITEMS,
                        className: "",
                        render: data => data?.totalItems,
                     },

                     {
                        title: COLUMNS.STATUS,
                        className: "",
                        render: data => (
                           <ActiveInactiveSwitch
                              isDisable={tableDataChangeLoading}
                              id={data.id}
                              name={`${data.id}`}
                              onChange={e =>
                                 handleStatusChange(data?.status, data?.id)
                              }
                              isChecked={
                                 data?.status ==
                                 EquipmentCategoriesStatus.Active
                              }
                           />
                        ),
                     },

                     {
                        title: COLUMNS.ACTIONS,

                        render: data => (
                           <div className="d-flex gap-2 pointer">
                              {/* <img
                                 src={editIcon}
                                 onClick={() => handleInitManage(data)}
                              /> */}

                              <img
                                 src={settingsIcon}
                                 onClick={() =>
                                    handleNavigateToCategoryItems(data)
                                 }
                              />
                              {/* <img
                                 src={DeleteIcon}
                                 onClick={() => handleInitDelete(data)}
                              /> */}
                           </div>
                        ),
                     },
                  ]}
               />
            </Col>
            <Pagination
               currentPage={page}
               totalData={total}
               onPageClick={handlePageClick}
            />
         </Row>

         <ManageSettingsCategory
            TITLES={TITLES}
            subTitle="Personalization"
            addApiFunc={CreateCustomizationCategory}
            entityApiKey="name"
            entityName="Personalization Category"
            fetchApiFunc={GetCustomizationCategoryDetails}
            statusConstObj={TrailerStatus}
            title="Category"
            textFieldPlaceholder="Enter name"
            updateApiFun={UpdateCustomizationCategory}
            textFieldLabel="Name"
            isOpen={eventsType.isManage}
            id={selection?.id}
            onSuccess={request}
            payloadData={{
               configuratorTrailerId: Number(trailerId),
            }}
            onToggleModal={() => {
               setSelection({})
               setEventsType(prev => ({
                  ...prev,
                  isManage: false,
               }))
            }}
            hasBaseFeature
         />
         <DeleteModal
            isOpen={openDelModal}
            toggleFunction={setOpenDelModal}
            id={selection.id}
            apiFunction={DeleteCustomizationCategory}
            title={"Personalization Category"}
            requestFunction={request}
         />
      </>
   )
}

export default Customization
