import { TrailerPublishStatus } from "../../../constants"
import * as Yup from "yup"

export const FIELDS_NAME = {
   SIZE: "size",
   PUBLISH_DATE: "publishingDate",
   PUBLISH_STATUS: "publishStatus",
   PRICE: "price",
   MODEL: "model",
   STATUS: "status",
   TRAILER_TYPE: "trailerType",
   YEAR: "year",
}

export const initialValues = {
   [FIELDS_NAME.MODEL]: [],
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.SIZE]: [],
   [FIELDS_NAME.PUBLISH_DATE]: new Date(),
   [FIELDS_NAME.PUBLISH_STATUS]: TrailerPublishStatus.UnPublished,
   [FIELDS_NAME.TRAILER_TYPE]: "",
   [FIELDS_NAME.YEAR]: new Date(),
   [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter valid amount")
      .test("", "Price must be between 1 and 8 digits", item => {
         let num = item?.toString()?.length
         return num <= 8 && num >= 1
      }),

   [FIELDS_NAME.MODEL]: Yup.array()
      .min(1, "At least one model must be selected")
      .required("Select model"),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
   [FIELDS_NAME.PUBLISH_DATE]: Yup.date()
      .nullable()
      .typeError("Please provide a valid date.")
      .required("Select a publish date"),
   [FIELDS_NAME.PUBLISH_STATUS]: Yup.string("Select publish status").required(
      "Select publish status"
   ),
   [FIELDS_NAME.YEAR]: Yup.date("Select year").required("Select year"),
   [FIELDS_NAME.SIZE]: Yup.array()
      .min(1, "At least one size must be selected")
      .required("Select size"),
   [FIELDS_NAME.TRAILER_TYPE]: Yup.object()
      .required("Select trailer type")
      .nullable("Select trailer type"),
})
