import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import "./Style.scss"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import { GetDepartmentInventoryListing } from "api/api.service"
import DeleteIcon from "assets/images/icons/finance-vendor-mang-del-icon.svg"
import AddNewItemSelectStyling from "./AddNewItemSelectStyling"
import { validateOptionSelect, makeOptionsDisabled } from "utils/commonUtils"
import { validateZero } from "utils/formUtils"

const COLUMNS = {
   SKU_NO: "SKU No.",
   ITEM_NAME: "Item Name",
   QUANTITY: "Quantity",
   ACTION: "Action",
}

const LIMIT = 100

const BuildDepartmentItems = ({
   isDetailView,
   selectedDepartment,
   mutateDepartment,
   selectedData = [],
   alreadyHadItems = [],
   departmentName = "",
   handleModalToggling,
}) => {
   let controller = new AbortController()
   let signal = controller.signal
   const { departmentId } = useParams()

   const [isAddingRequest, setIsAddingRequest] = useState()
   const [selectedItems, setSelectedItems] = useState(null)
   const [previousSelectedItems, setPreviousSelectedItems] = useState([
      ...selectedData,
   ])

   const [itemsListing, setItemsListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      isDataAvailable: true,
   })

   useEffect(() => {
      itemsListing.page && handleGetItemsListing(itemsListing.page)

      return () => {
         controller.abort()
      }
   }, [itemsListing.Q])

   const handleSelectOption = option => {
      if (
         typeof option != "object" ||
         validateOptionSelect(option, previousSelectedItems, "id") ||
         !Object.keys(option)
      )
         return
      setPreviousSelectedItems(prev => {
         return [...prev, { ...option, requiredQuantity: "", hasError: false }]
      })
   }
   const handleSearch = (value = "", callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   const handleGetItemsListing = page => {
      let itemsIdArr, test

      if (previousSelectedItems.length > 0 || alreadyHadItems.length > 0) {
         let combineArr = [...alreadyHadItems, ...previousSelectedItems]

         itemsIdArr = combineArr?.map(item => item.itemsId)
      }

      setItemsListing(prev => {
         return {
            ...prev,
            fetching: true,
         }
      })
      GetDepartmentInventoryListing(
         {
            limit: LIMIT,
            ...(itemsListing.Q && { Q: itemsListing.Q }),
            Page: page,
            departmentId: [selectedDepartment.id],
         },
         signal
      )
         .then(res => {
            setItemsListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length == LIMIT,
               data: (() => {
                  test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: `${item.skuNo} - ${item.itemName}`,
                  }))

                  if (previousSelectedItems.length || alreadyHadItems.length) {
                     test = test.filter(item => !itemsIdArr.includes(item.id))
                  }

                  return [...test]
               })(),
            }))
         })
         .finally(() => {
            setItemsListing(prev => {
               return {
                  ...prev,
                  fetching: false,
               }
            })
         })
   }

   const handleSetItemQuantity = (evt, targetObj, index) => {
      const modifiedValue = evt.target.value
      let indexOfTargetObj = index
      let stateCopy = [...previousSelectedItems]
      let refObj = (stateCopy[indexOfTargetObj] = {
         ...targetObj,
      })

      refObj.requiredQuantity = modifiedValue

      setPreviousSelectedItems(stateCopy)
   }

   const handleDeleteSelectedItem = sku => {
      let filteredData = previousSelectedItems.filter(obj => obj.skuNo != sku)
      setPreviousSelectedItems([...filteredData])
      if (filteredData.length < 1) {
         setSelectedItems(null)
      }
      itemsListing?.data.map(el => {
         if (el.skuNo === sku) {
            el.isDisabled = false
         }
      })
   }

   const handleSubmitItemCatalog = async e => {
      e.preventDefault()

      if (validateZero(previousSelectedItems, "requiredQuantity")) {
         toast.error("Item's quantity can't be 0 !")
         return
      }

      let formattedItemsList = previousSelectedItems.map(obj => {
         return {
            itemsId: obj.itemsId || obj.id,
            quantity: Math.abs(obj.requiredQuantity),
            requiredQuantity: Math.abs(obj.requiredQuantity),
            itemName: obj.itemName,
            skuNo: obj.skuNo,
         }
      })
      let cloneOfDepartment = { ...selectedDepartment }
      cloneOfDepartment.items = [...formattedItemsList]

      mutateDepartment(cloneOfDepartment)
   }

   return (
      <form onSubmit={handleSubmitItemCatalog}>
         <h3
            className=" inv-req-popup-heading px-3"
            style={{
               fontWeight: "bold",
               color: "black",
            }}
         >
            Station: {departmentName}
         </h3>

         {!isDetailView && (
            <div className="pt-3 pb-4  d-flex align-items-center px-3">
               <span
                  className="font-size-13 "
                  style={{
                     width: "100px",
                     fontWeight: "bold",
                     color: "black",
                  }}
               >
                  Add Item
               </span>
               <div style={{ width: "250px" }}>
                  <AutoComplete
                     placeholder="Search Item"
                     classNamePrefix="add-new-req"
                     value={selectedItems}
                     customStyles={AddNewItemSelectStyling}
                     onInputChange={val => handleSearch(val, setItemsListing)}
                     options={makeOptionsDisabled(
                        previousSelectedItems,
                        itemsListing
                     )}
                     onChange={val => handleSelectOption(val)}
                     closeMenuOnSelect={true}
                     isLoading={itemsListing?.fetching}
                     handleBlur={() =>
                        setItemsListing(prev => {
                           return {
                              ...prev,
                              page: 1,
                              Q: "",
                           }
                        })
                     }
                  />
               </div>
            </div>
         )}
         <DataTable
            data={previousSelectedItems}
            className="view-detail-inv-req-table  height-scroll-table w-100"
            tableClasses="add-new-request-table"
            config={[
               {
                  title: COLUMNS.SKU_NO,
                  render: data => {
                     return data?.skuNo
                  },
               },
               {
                  title: COLUMNS.ITEM_NAME,
                  render: data => {
                     return data?.itemName
                  },
               },
               {
                  title: COLUMNS.QUANTITY,
                  render: (data, idx) => {
                     return (
                        <div className="">
                           <TextInput
                              disabled={isDetailView}
                              onChange={evt =>
                                 handleSetItemQuantity(evt, data, idx)
                              }
                              className="view-detail-inv-req-p-ip-con me-4 me-md-5"
                              labelClass="d-none"
                              type="number"
                              step="0.01"
                              min={0.1}
                              inputClass="view-detail-inv-req-p-ip"
                              value={data.requiredQuantity}
                              placeholder="Quantity"
                           />
                        </div>
                     )
                  },
               },

               {
                  title: COLUMNS.ACTION,
                  render: data => {
                     return (
                        <img
                           style={{
                              pointerEvents: isDetailView ? "none" : "default",
                              opacity: isDetailView ? 0.1 : 1,
                           }}
                           onClick={() => handleDeleteSelectedItem(data.skuNo)}
                           className="view-detail-modal-delete"
                           src={DeleteIcon}
                        />
                     )
                  },
               },
            ]}
         />
         <div className="text-center my-4">
            <Button
               isLoading={isAddingRequest}
               size="lg"
               className="gt-btn-grad-primary table-bottom-btn m-0 p-0 btn-alignment"
               title={isDetailView ? "Done" : "Save Changes"}
               type={isDetailView ? "button" : "submit"}
               onClick={isDetailView && handleModalToggling}
            />
         </div>
      </form>
   )
}

export default BuildDepartmentItems
