import React from "react"
import ReactDOM from "react-dom/client"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { store, persistor } from "./store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import "react-quill/dist/quill.snow.css"

import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </PersistGate>
   </Provider>
)

serviceWorker.unregister()
