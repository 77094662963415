import React from "react"
import { useFormik } from "formik"
import {
   Card,
   CardBody,
   Col,
   Form,
   FormGroup,
   Input,
   Label,
   Row,
} from "reactstrap"

import { TextInput } from "components/TextInput"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { initialNoteValue, validationNoteSchema } from "./FormConfig"

const COLUMNS = {
   SKU_NUMBER: "SKU No.",
   EXISTING_QTY: "Existing Qty.",
   CSV_QTY: "New Qty.",
   ADJUSTMENTS: "Adjustments",
}

const UpdateSelectedItemsModal = ({
   title,
   selectedItems,
   onClose,
   onSend,
   csvQuantities,
   setCsvQuantities,
   loading = false,
}) => {
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: initialNoteValue,
      validationSchema: validationNoteSchema,
      onSubmit: values => {
         onSend(values)
      },
   })
   const handleCSVQtyChange = (id, value) => {
      setCsvQuantities(prevState => ({
         ...prevState,
         [id]: value,
      }))
   }
   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Card className="mb-5">
               <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                  <h3 className="text-center inv-req-popup-heading mb-0">
                     {title}
                  </h3>
                  <DataTable
                     data={selectedItems}
                     tableClasses="finance-im-wh-chemicals"
                     showCheckboxes={false}
                     config={[
                        {
                           title: COLUMNS.SKU_NUMBER,
                           render: data => <>{data?.skuNo || "-"}</>,
                        },
                        {
                           title: COLUMNS.EXISTING_QTY,
                           render: data => data?.warehouseQuantity || "-",
                        },
                        {
                           title: COLUMNS.CSV_QTY,
                           render: data => (
                              <TextInput
                                 type="number"
                                 value={csvQuantities[data.id]}
                                 onChange={e =>
                                    handleCSVQtyChange(data.id, e.target.value)
                                 }
                                 min={0}
                                 step="0.01"
                                 placeholder="Enter Qty"
                                 max="99999999.99"
                              />
                           ),
                        },
                     ]}
                  />
               </CardBody>
            </Card>

            <div className="m-4">
               <Row className="mt-5 px-4">
                  <Col sm="12" xs="12" md="12" lg="12" className="px-0 mb-4">
                     <FormGroup row className="align-items-center">
                        <Label
                           for="exampleEmail"
                           sm={2}
                           md={2}
                           lg={2}
                           align="right"
                           className=" pe-md-3 ps-0 pe-0 addnewitem-typo textarea-label"
                        >
                           Adjustments Notes
                        </Label>
                        <Col
                           sm={9}
                           md={10}
                           lg={10}
                           className="textarea-ip-con ps-md-0 ps-3 pe-4"
                        >
                           <Input
                              // disabled={isReadOnly}
                              // type="textarea"
                              name="note"
                              onChange={formik.handleChange}
                              onBlur={formik.handleChange}
                              value={formik.values.note}
                              // id="exampleEmail"
                              // className="textarea-max-width"
                              id="note"
                              // type="text"
                              type="textarea"
                              // value={note}
                              // onChange={e => setNote(e.target.value)}
                           />
                           {formik.touched.note && formik.errors.note ? (
                              <span className="error fs-10">
                                 {formik.errors.note}
                              </span>
                           ) : null}
                        </Col>
                     </FormGroup>
                  </Col>
               </Row>

               {selectedItems.length > 0 && (
                  <div className="d-flex justify-content-center ">
                     <Button
                        onClick={onClose}
                        size="sm"
                        title="Cancel"
                        className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                     />

                     <Button
                        type="submit"
                        size="lg"
                        title="Update New"
                        className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header"
                        isLoading={loading}
                     />
                  </div>
               )}
            </div>
         </Form>
      </>
   )
}

export default UpdateSelectedItemsModal
