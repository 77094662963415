import { fetchUtil } from "./fetchUtil"
import { store } from "../store/index"
import { MediaServerTypes } from "../constants/"
import { removeSpaceUnderscoreCharacterFromString } from "./commonUtils"

export const formatBytes = (bytes, decimals = 2) => {
   if (bytes === 0) return "0 Bytes"
   const k = 1024
   const dm = decimals < 0 ? 0 : decimals
   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

   const i = Math.floor(Math.log(bytes) / Math.log(k))
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const toBase64 = (file, callback) => {
   if (!file) return
   let reader = new FileReader()
   reader.readAsDataURL(file)
   reader.onload = function () {
      callback(reader.result)
   }
   reader.onerror = function (error) {}
}

export const initializeMediaUtil = (file, isPublic = false, signal) => {
   const token = store.getState().userAuth.user?.Token

   return fetchUtil({
      url: isPublic ? "/media/upload/public/init" : "/media/init",
      body: JSON.stringify({
         name: removeSpaceUnderscoreCharacterFromString(file.name),
         type: classifyFile(file),
         size: file.size,
      }),
      method: "POST",
      ...(!isPublic && { token }),
      abortSignal: signal,
   })
      .then(res => {
         return Promise.resolve(res)
      })
      .catch(error => {
         return Promise.reject(error)
      })
}

let isConfigUpdated = false

export const uploadOnS3 = async (stream, credentials, cb = () => {}) => {
   if (!window.AWS) {
      return
   }
   if (!isConfigUpdated) {
      window.AWS.config.update({ region: credentials.region })
      isConfigUpdated = true
   }
   let s3 = new window.AWS.S3({
      credentials: new window.AWS.Credentials({
         accessKeyId: credentials.accessKeyId,
         secretAccessKey: credentials.secretAccessKey,
         sessionToken: credentials.sessionToken,
         signatureVersion: "v4",
         region: credentials.region,
      }),
   })

   let uploadedItem = await s3
      .upload({
         Bucket: credentials.bucket,
         Key: credentials.location,
         ACL: "public-read",
         Body: stream,
      })
      .on("httpUploadProgress", function (progress) {
         cb(getUploadedProgress(progress.loaded, progress.total))
      })
      .promise()
   return uploadedItem
}

export const getUploadedProgress = (uploadedSize, totalSize) => {
   let uploadedProgress = (uploadedSize / totalSize) * 100
   return Number(uploadedProgress.toFixed(0))
}

export const getMediaStream = async file => {
   return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = e => {
         resolve(e.target.result)
      }
      reader.onerror = err => {
         reject(false)
      }
      reader.readAsArrayBuffer(file)
   })
}

export const finalizeMediaUtil = (id, isPublic = false, signal) => {
   const token = store.getState().userAuth.user?.Token

   return fetchUtil({
      url: isPublic ? "/media/upload/public/finalize" : "/media/finalize",
      body: JSON.stringify({
         id,
      }),
      method: "POST",
      ...(!isPublic && { token }),
      abortSignal: signal,
   })
      .then(res => {
         return Promise.resolve(res)
      })
      .catch(error => {
         return Promise.reject(error)
      })
}

export const getMediaPath = (mediaObj = null) => {
   if (mediaObj?.media?.path || mediaObj?.path) {
      return `${mediaObj?.media?.path || mediaObj?.path}`
   }
   return null
}

export const getImageFromMedia = media => {
   for (let i in media) {
      if (media[i].Type === MediaServerTypes.Image) {
         return media[i]
      }
   }
   return ""
}

export function classifyFile(file) {
   const imageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/svg+xml",
   ]
   const documentTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
      "text/html",
      "text/csv",
      "application/rtf",
   ]

   // Check if the file type is one of the image types
   if (imageTypes.includes(file.type)) {
      return "IMAGE"
   }
   // Check if the file type is one of the document types
   else if (documentTypes.includes(file.type)) {
      return "DOCUMENT"
   } else {
      return "UNKNOWN" // For types that are neither in imageTypes nor in documentTypes
   }
}
export const getPreviewFile = file => {
   if (file && file.name) {
      return file && URL.createObjectURL(file)
   }
   return file
}
